import { REMOVE_ALERT, SET_ALERT, CLEAR_ALERTS } from '../actions/types';

const intialState = [];

const alert = (state = intialState, action) => {
    const { type, payload } = action;

    switch(type) {
        case SET_ALERT:
            return [...state, payload];
        case REMOVE_ALERT:
            return state.filter(alert => alert.id !== payload);
        case CLEAR_ALERTS:
            return state.alert = [];
        default:
            return state;
    }
}

export default alert
