export const SET_ALERT = 'SET_ALERT'
export const REMOVE_ALERT = 'REMOVE_ALERT'
export const CLEAR_ALERTS = 'CLEAR_ALERTS'

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const USER_REQUESTED = 'USER_REQUESTED'
export const USER_LOADED = 'USER_LOADED'
export const AUTH_ERROR = 'AUTH_ERROR'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

export const GET_WORKOUTS = 'GET_WORKOUTS'
export const WORKOUT_REQUESTED = 'WORKOUT_REQUESTED'
export const GET_WORKOUT_BY_ID = 'GET_WORKOUT_BY_ID'
export const GET_CATEGORIES = 'GET_CATEGORIES'
export const WORKOUT_ERROR = 'WORKOUT_ERROR' // not used
export const SET_WORKOUT = 'START_WORKOUT'
