import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Link from "@material-ui/core/Link";
import AppBar from "../components/AppBar";
import Toolbar, { styles as toolbarStyles } from "../components/Toolbar";

// import clsx from "clsx"

const styles = theme => ({
  title: {
    fontSize: 24,
    '&:hover': {
      color: '#fff',
      textDecoration: 'none'
    }
  },
  posabs: {
    position: 'absolute'
  },
  placeholder: toolbarStyles(theme).root,
  toolbar: {
    justifyContent: "space-between"
  },
  left: {
    flex: 1
  },
  leftLinkActive: {
    color: theme.palette.common.white
  },
  right: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end"
  },
  rightLink: {
    fontSize: 16,
    color: theme.palette.common.white,
    marginLeft: theme.spacing(3),
    '&:hover': {
      textDecoration: 'none'
    }
  },
  primaLink: {
    border: '1px solid #fff',
    borderRadius: '20px',
    padding: '3px 6px 0px 6px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff'
    }
  },
  otherLink: {
    paddingTop: '4px',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  linkSecondary: {
    color: theme.palette.secondary.main
  }
});

const NavbarView = ({ classes }) => {
  return (
    <div className={classes.posabs}>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          <div className={classes.left}>
            <Link
              variant="h6"
              underline="none"
              color="inherit"
              className={classes.title}
              href="/"
            >
              {"WhatIsRock"}
            </Link>
          </div>
          <div className={classes.right}>
            <Link
              color="inherit"
              variant="h6"
              underline="none"
              className={`${classes.rightLink} ${classes.otherLink}`}
              href="/login"
            >
              {"Login"}
            </Link>
            <Link
              variant="h6"
              underline="none"
              className={`${classes.rightLink} ${classes.primaLink}`}
              href="/signup"
            >
              {"Sign Up"}
            </Link>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.placeholder} />
    </div>
  );
};

NavbarView.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(NavbarView);
