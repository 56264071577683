import React, { useState } from 'react'

export const Set = ({ set, num, exId, exArrId, passData, workoutActive, setAlert }) => {

    const [ completeness, setCompleteness ] = useState(set.complete ? true : false)
    const { reps, weight, rest } = set

    const weightView = (weight > 0) ? `Weight: ${weight} ` : null

    const checkboxView = 
        <div className="custom-control custom-checkbox text-center set-check">
            <input
                type="checkbox"
                className="custom-control-input check-large"
                id={`${exId}-${num}`}
                onChange={(e) => {
                    passData(rest, e.target.checked, exArrId, num-1);
                    setCompleteness(!completeness);
                }}
                disabled={ !workoutActive }
                checked={completeness} />
            <label
                className="custom-control-label check-text"
                htmlFor={`${exId}-${num}`}>
            </label>
        </div>
    
    const restView = ( rest > 0 ) ? <span>Rest: {rest}</span> : null

    return (
        <div className="set-wrapper">
            <p className="set">
                <span className="set-number">{num}</span>
                Reps: { reps } - { weightView }  { restView }
            </p>
            { checkboxView }
        </div>
    )
}