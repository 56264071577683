import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { composeWithDevTools } from 'redux-devtools-extension'

import reducer from './reducers'

const initialState = {}

const persistConfig = {
    key: 'root',
    storage,
}

const persistedReducer = persistReducer(persistConfig, reducer)

const stringMiddleware = () => (next) => (action) => {
    if (typeof action === 'string') {
        return next({
            type: action
        })
    }
    return next(action)
}

const store = createStore(persistedReducer, initialState,
    composeWithDevTools(applyMiddleware(thunkMiddleware, stringMiddleware))
)

const persistor = persistStore(store)

export default store
export { persistor }