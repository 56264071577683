import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Typography from "../components/Typography";
// import TextField from "../components/TextField";

import imgBase from '../content-config'

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.secondary.light
  },
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: "flex"
  },
  iconsWrapper: {
    height: 120
  },
  icons: {
    display: "flex"
  },
  icon: {
    width: 48,
    height: 48,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  list: {
    margin: 0,
    listStyle: "none",
    paddingLeft: 0
  },
  listItem: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  },
  linkDoc: {
    '&:hover': {
      color: theme.palette.secondary.main,
      textDecoration: 'none'
    }
  }
}));

export default function AppFooter() {
  const classes = useStyles();

  return (
    <Typography component="footer" className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={3}>
          <Grid align="center" item xs={12} sm={12} md={6}>
            <Typography variant="h6" gutterBottom>
              Legal
            </Typography>
            <ul className={classes.list}>
              <li className={classes.listItem}>
                <Link className={classes.linkDoc} href="/">Terms</Link>
              </li>
              <li className={classes.listItem}>
                <Link className={classes.linkDoc} href="/">Privacy</Link>
              </li>
            </ul>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <Grid
              container
              direction="column"
              alignItems="center"
              className={classes.iconsWrapper}
              spacing={2}
            >
              <Grid item className={classes.icons}>
                <a href="https://facebook.com/" className={classes.icon}>
                  <img
                    src={`${imgBase}/facebook.svg`}
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://twitter.com/"
                  className={classes.icon}
                >
                  <img
                    src={`${imgBase}/twitter.svg`}
                    alt="Twitter"
                  />
                </a>
              </Grid>
              <Grid item>© 2023 WhatIsRock</Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Typography>
  );
}
