import React from 'react'
import * as backtrace from 'backtrace-js'

import './des-sys.css'

backtrace.initialize({
    endpoint: 'https://testing-pm-alexsmirnov.sp.backtrace.io:6098',
    token: '35b0d47f878ab49f331d851806249b98f65b04e0c8d732fd358b0c4454eb28f8',
})

backtrace.report(new Error('something broke'))
backtrace.report(new Error('different error - 2'))
backtrace.report(new Error('something happened - 3'))
backtrace.report(new Error('here we go again - 4'))

const DesignSystem = () => {
    return (
        <div>
            <h3>Color Palette</h3>
            <div className="container palette-text">
                <div className="row row-palette">
                    <div className="col or-back-1">
                        Or-1
                    </div>
                    <div className="col or-back-2">
                        Or-2
                    </div>
                    <div className="col or-back-3">
                        Or-3
                    </div>
                    <div className="col or-back-4">
                        Or-4
                    </div>
                    <div className="col or-back-5">
                        Or-5
                    </div>
                </div>
                <div className="row row-palette">
                    <div className="col aq-back-1">
                        Aq-1
                    </div>
                    <div className="col aq-back-2">
                        Aq-2
                    </div>
                    <div className="col aq-back-3">
                        Aq-3
                    </div>
                    <div className="col aq-back-4">
                        Aq-4
                    </div>
                    <div className="col aq-back-5">
                        Aq-5
                    </div>
                </div>
                <div className="row row-palette">
                    <div className="col gr-back-1">
                        Gr-1
                    </div>
                    <div className="col gr-back-2">
                        Gr-2
                    </div>
                    <div className="col gr-back-3">
                        Gr-3
                    </div>
                    <div className="col gr-back-4">
                        Gr-4
                    </div>
                    <div className="col gr-back-5">
                        Gr-5
                    </div>
                </div>
                <div className="row row-palette">
                    <div className="col bl-back-1">
                        Bl-1
                    </div>
                    <div className="col bl-back-2">
                        Bl-2
                    </div>
                    <div className="col bl-back-3">
                        Bl-3
                    </div>
                    <div className="col bl-back-4">
                        Bl-4
                    </div>
                    <div className="col bl-back-5">
                        Bl-5
                    </div>
                </div>
                <div className="row">
                    <div className="col col-gradient gradient-or">
                        Grad-or
                    </div>
                    <div className="col col-gradient gradient-aq">
                        Grad-aq
                    </div>

                </div>
            </div>
        </div>
    )
}

export default DesignSystem
