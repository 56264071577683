import BackgroundWrapper from '../layout/background'
import characterImage from './Character.png'
import { Link } from 'react-router-dom'
import { AboutUsIcon } from '../../assets/icons'

import './character.css'


const Character = () => {
    return (
        <div>
            <BackgroundWrapper />
            <div className='content-desktop'>
                <img className="img-center-animated img-profile" src={characterImage} alt="Profile Icon" />
                <div className="profile-menu shadow">
                    <h3 className="text-center mt-4">The Mighty Might</h3>
                    <p className="text-center mt-8 col-orange-1">Level: 8 | No Active Quest <span class="notes-icon">!</span></p>
                    <ul className='character-stats-list'>
                        <Link to="/skill-page"><li className='char-list-item'><div className='char-list-icon'><AboutUsIcon /></div><p>Strength: 27</p></li></Link>
                        <Link to="/skill-page"><li className='char-list-item'><div className='char-list-icon'><AboutUsIcon /></div><p>Dexterity: 14</p></li></Link>
                        <Link to="/skill-page"><li className='char-list-item'><div className='char-list-icon'><AboutUsIcon /></div><p>Rank: 57</p></li></Link>
                        <Link to="/skill-page"><li className='char-list-item'><div className='char-list-icon'><AboutUsIcon /></div><p>Clan: Fallout Warriors</p></li></Link>
                    </ul>
                </div>
                <p></p>
            </div>
        </div>
    )
}

export default Character