import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { loadUser } from '../../actions/auth'

import { transDateMDY } from '../helpers'

import Spinner from '../layout/spinner'
import BackgroundWrapper from '../layout/background'
import { musclesBadges } from '../layout/elements'

import './workout-log.css'

const WorkoutLog = ({ workoutLog, loadUser, loading }) => {

    useEffect(() => {
        loadUser()
    }, [loadUser])

    const logView = workoutLog.slice(0).reverse().map((workout, n) => {
        return (
            <li key={n}>
                <Link to={`/workouts/${workout.woId}`}>
                    <p>
                        "{workout.name}"
                    </p>
                    <div className="log-record-date">{transDateMDY(workout.date)}</div>
                    <div className="muscle-list">
                        {musclesBadges(workout.muscles)}
                    </div>
                </Link>
            </li>
        )
    })
    return (loading) ? <Spinner /> : (
        <div>
            <BackgroundWrapper />
            <ul className="log-list">
                {logView}
            </ul>
        </div>
    )
}
const mapStateToProps = state => ({
    workoutLog: state.auth.user.workoutLog,
    loading: state.auth.loading
})

export default connect(mapStateToProps, { loadUser })(WorkoutLog)
