import React from 'react'
import { Link } from 'react-router-dom'
import { transDateMDYHMA } from '../../helpers'

import './label.css'

import configData from '../../../config.json'

const imgBase = `${configData.SERVER_URL}events/`

const EventLabel = ({ event, userId }) => {

    const { _id, name, image, date, schedule, attendees } = event

    const dateView = date ? transDateMDYHMA(date) : schedule

    return (
        <div className='cat-card'>
            <div className='card shadow'>
                <div className='date-badge'>{ dateView }</div>
                { attendees.map(at => at['userId']).includes(userId) ? <div className='left-badge shadow'>Attending!</div> : '' }
                <Link to={`/events/${_id}`} >
                    <img src={`${imgBase}${image}`} alt='event' />
                    <div className='title gradient-light-blue'></div>
                    <p className='col-blue-1 white-text-shadow'>{ name }</p>
                </Link>
            </div>
        </div>
    )
}

export default EventLabel
