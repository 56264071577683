import React, { useState } from 'react'

import Collapsible from 'react-collapsible'

import configData from '../../config.json'
import { musclesBadges } from '../layout/elements'

const imgBase = `${configData.SERVER_URL}exercises/`


export const ExerciseItem = ({ exercise, open }) => {

    const [ image, setImage ] = useState('https://axrock.s3.amazonaws.com/content/misc/image-spinner.svg')

    const ifLast = (arr, n) => {
        if (arr.length <= (n + 1)) {
            return '.'
        } else {
            return ', '
        }
    }

    const colTitle = <div className="ex-list-item">
                        <span className="ex-name">{ exercise.name }</span>
                        <div className="ex-mus-list">
                            { musclesBadges(exercise.primaryMuscles) }
                        </div>
                    </div>

    return (
        <Collapsible
            trigger={ colTitle }
            transitionTime={ 300 }
            onOpening={ () => setImage(`${imgBase}${exercise.image}`) }
            open={open}
        >
            <div className="exercise">
                <div className="timer-wrapper ex-image">
                    <div className="gradient"></div>
                    <div className="gradient-side"></div>
                    <img
                        src={ image }
                        alt="exercise"
                        />
                </div>
                <p className="ex-list-description">Primary Muscles: {
                        exercise.primaryMuscles.map((muscle, n) => {
                            return <span key={n}>{ muscle }{ ifLast(exercise.primaryMuscles, n) }</span>
                        })
                    }
                </p>
            </div>
        </Collapsible>
    )
}

export default ExerciseItem