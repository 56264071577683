import React from 'react'
import { Link } from 'react-router-dom'

import './label.css'

import configData from '../../../config.json'

const imgBase = `${configData.SERVER_URL}workouts/`

const Workoutlabel = ({ workout }) => {
    const { woId, name, image, level } = workout

    const levelView = level ? <div className="level-badge">{ level }</div> : null
    return (
        <div className="cat-card">
            <div className="card shadow">
                { levelView }
                <Link to={`/workouts/${woId}`} >
                    <img src={`${imgBase}${image}`} alt="workout" />
                    <div className="title gradient-light-orange"></div>
                    <p className='col-orange-1 white-text-shadow'>{ name }</p>
                </Link>
            </div>
        </div>
    )
}

export default Workoutlabel
