import React, { useEffect, useState } from 'react'
import { EventLabel } from '../layout/labels'
import axios from 'axios'
import moment from 'moment'
import { connect } from 'react-redux'

import { loadUser } from '../../actions/auth'

import PropTypes from 'prop-types'

import Spinner from '../layout/spinner'
import BackgroundWrapper from '../layout/background'
import EmptyView from '../layout/empty-view'

import configData from '../../config.json'

const reqBase = `${configData.API_URL}events`

const Events = ({ loading = true, userId }) => {

    const [ filters, setFilters ] = useState({
        date: '',
        current: true
    })
    const [ events, setEvents ] = useState(null)
    const [ load, setLoad ] = useState(loading)

    useEffect(() => {
        axios.get(reqBase).then((res) => {
            setEvents(res.data.sort((a, b) => moment(a.date) - moment(b.date)))
            loadUser()
            setLoad(false)
        })
    }, [setEvents])

    const filterEvents = (events, filters) => {
        if (filters.current) {
            return events.filter((event) => (moment(event.date).add(1, 'hours') >= moment()))
        } else {
            return events.filter((event) => (moment(event.date).add(1, 'hours') < moment()))
        }
    }

    const eventsListView = events && filterEvents(events, filters).map(
        (event) => <EventLabel key={event._id} event={event} userId={userId} />
    )

    return (load) ? <Spinner /> : (
        <div className='list-wrapper'>
            <BackgroundWrapper />
            <button className='btn-aqua filter-btn' 
                onClick = {() => {
                    setFilters({current: !filters.current})
                }}>
                {filters.current ? 'Show Archive' : 'Show Current'}
            </button>
            { 
                (filterEvents(events, filters).length > 0) ? eventsListView : <EmptyView message='New events coming soon :)' />
            }
        </div>
    )
}

Events.propTypes = {
    loadUser: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    userId: state.auth.user && state.auth.user._id
})

export default connect(mapStateToProps, { loadUser })(Events)
