import React, { Fragment, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { login } from '../../../actions/auth'
import logo from '../logo.png'

import './login.css'

const Login = ({ login, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        email: '',
        password:''
    })

    const {email, password} = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async e => {
        e.preventDefault()
        login(email, password)
    }

    // Redirect if logged in
    if (isAuthenticated) {
        return <Redirect to="/home" />
    }

    return (
        <Fragment>
            <div className="back">
                
                <Link to="/"><img src={logo} className="logo-img" alt="logo" /></Link>
                <div className="d-flex justify-content-center">
                    <section className="auth-form shadow">
                        <h1>Sign In</h1>
                        <p className="lead">Sign Into Your Account</p>
                        <form className="form" onSubmit={e => onSubmit(e)}>
                            <div className="form-group">
                                <input
                                    className="form-control line-input"
                                    type="email"
                                    placeholder="Email Address"
                                    name="email"
                                    required
                                    value={email}
                                    onChange={e => onChange(e)}

                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control line-input"
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    minLength="6"
                                    value={password}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <input type="submit" className="btn-aqua btn-auth shadow" value="Login" />
                        </form>
                        <p className="signup-text">
                            Don't have an account? <Link className="orange-link" to="/signup">Sign Up</Link>
                        </p>
                        <Link className="orange-link d-flex justify-content-center" to="/passrecovery">Forgot Password</Link>
                    </section>
                </div>
            </div>
        </Fragment>
    )
}

Login.propTypes = {
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { login })(Login)
