import React, { useEffect, useState } from 'react'
import axios from 'axios'

import Spinner from '../layout/spinner'
import ExerciseItem from './exercise-item'
import BackgroundWrapper from '../layout/background'

import configData from '../../config.json'
import './exercise-list.css'

export const ExerciseList = () => {

    const [ exercises, setExercises ] = useState(null)
    const [ colOpen, setColOpen ] = useState(null)
    const [ query, setQuery ] = useState('')

    const onChange = e => {
        setQuery(e.target.value)
        if (colOpen === null) {
            setColOpen(false)
         } else {
            setColOpen(null)
        }
    }

    useEffect(() => {
        axios.get(`${configData.API_URL}exercises`).then((res) => {
            setExercises(res.data)
        })

    }, [setExercises])

    return (exercises === null) ? <Spinner /> : (
        <div>
            <BackgroundWrapper />
            <div className='ex-list-content pad-bot'>
                <input
                    className="search-input"
                    type="search"
                    placeholder="Search exercise"
                    value={query}
                    onChange={e => onChange(e)}
                />
                {
                    exercises && exercises.filter(ex => {
                        if (query === '') {
                            return ex
                        } else if (ex.name.toLowerCase().includes(query.toLowerCase())) {
                            return ex
                        }
                        return null
                    }).map((ex, n) => {
                        return <ExerciseItem exercise={ex} key={n} open={colOpen} />
                    })
                }
            </div>
        </div>
    )
}

export default ExerciseList
