import React, { Fragment, useState, useEffect } from 'react'
import { Link, Redirect, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setAlert } from '../../../actions/alert'
import { checkRecoveryToken, setNewPassword } from '../../../actions/user'
import logo from '../logo.png'

import './set-pass.css'

const SetPass = ({ setAlert, checkRecoveryToken, setNewPassword, isAuthenticated }) => {

    const { id } = useParams()

    const [formData, setFormData] = useState({
        password:'',
        password2:''
    })
    const [tokenResult, setTokenResult] = useState(true)

    useEffect(() => {
        async function tokenValidation() {
            let validationResult = await checkRecoveryToken(id)
            setTokenResult(validationResult)
        }
        tokenValidation()
    }, [checkRecoveryToken, id])

    const { password, password2 } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async e => {
        if (password !== password2) {
            setAlert('Passwords do not match', 'danger')
        } else {
            setNewPassword(id, password)
        }
        setFormData({ password:'', password2:'' })
        e.preventDefault()
    }

    if (isAuthenticated) {
        return <Redirect to="/home" />
    }

    if (!tokenResult) {
        return <Redirect to="/login" />
    }

    return (
        <Fragment>
            <div className="back">
                <Link to="/"><img src={logo} className="logo-img" alt="logo" /></Link>
                <div className="d-flex justify-content-center">
                    <section className="auth-form shadow">
                        <h1>Set <br/> Password</h1>
                        <p className="lead">Type your new password</p>
                        <form className="form" onSubmit={e => onSubmit(e)}>
                            <div className="form-group">
                                <input
                                    className="form-control line-input"
                                    type="password"
                                    placeholder="New Password"
                                    name="password"
                                    required
                                    value={password}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control line-input"
                                    type="password"
                                    placeholder="Confirm Password"
                                    name="password2"
                                    required
                                    value={password2}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <input type="submit" className="btn-aqua btn-auth shadow" value="Submit" />
                        </form>
                        <Link className="orange-link d-flex justify-content-center" to="/login">Back to Login</Link>
                    </section>
                </div>
            </div>
        </Fragment>
    )
}

SetPass.propTypes = {
    setNewPassword: PropTypes.func.isRequired,
    checkRecoveryToken: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { setAlert, checkRecoveryToken, setNewPassword })(SetPass)
