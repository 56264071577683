import React from 'react'
import { Workoutlabel } from '../layout/labels'
import BackgroundWrapper from '../layout/background'

const PersonalWorkoutList = ({ workouts }) => {

    const workoutsView = workouts.map((workout) => {
        return (
            <Workoutlabel key={`${workout.woId}-${workout.name}`} workout={workout} />
        )
    })

    return (
        <div className='list-wrapper'>
            <BackgroundWrapper />
            {workoutsView}
        </div>
    )

}

export default PersonalWorkoutList
