import React, { Fragment, useState } from 'react'
import personalImage from './personal.png'
import BackgroundWrapper from '../layout/background'

const RequestPersonalWorkout = ({ requestPersonalPlan, setAlert }) => {
    const [formData, setFormData] = useState({
        message:''
    })

    const { message } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async e => {
        if (message.length < 10) {
            setAlert('Please include a description of more than 10 characters', 'danger', 3000)
        } else {
            requestPersonalPlan(message)
            setFormData({ ...formData, message: '' })
        }
        e.preventDefault()
    }

    return (
        <Fragment>
            <BackgroundWrapper />
            <img className="img-center-animated img-req-workout" src={personalImage} alt="Personal Workout" />
            <p className="top-text">
                You may request a Personal Workout Plan to meet your goals even faster!
            </p>
            <div className="d-flex justify-content-center">
                <section>
                    <form className="form" onSubmit={e => onSubmit(e)}>
                        <div className="form-group">
                            <textarea
                                className="form-control input-text"
                                placeholder="Please specify your goals, target muscles, number of weekly workouts, and health issues"
                                name="message"
                                required="required"
                                rows="7"
                                value={message}
                                onChange={e => onChange(e)}>
                            </textarea>
                        </div>
                        <input type="submit" className="btn-aqua btn-request shadow" value="Request a plan" />
                    </form>
                </section>
            </div>
        </Fragment>
    )
}

export default RequestPersonalWorkout
