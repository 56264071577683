import React from 'react'
import Countdown from 'react-countdown'

const Timer = ({ time, completeFunc }) => {

    return (
        <Countdown
            date={Date.now() + time * 1000}
            renderer={(props) => {
                if (props.completed) {
                    // Render a completed state
                    return null
                } else {
                    // Render a countdown
                    return (
                        <span>{props.formatted.minutes}:{props.formatted.seconds}</span>
                    )
                }

            }}
            onComplete={completeFunc}
        />

    )
}

export default Timer
