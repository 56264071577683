import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import { ArrowDownwardIcon } from "@material-ui/icons";
import Button from "../components/Button";
import Typography from "../components/Typography";
import ProductHeroLayout from "./ProductHeroLayout";

import imgBase from "../content-config";

const backgroundImage = `${imgBase}/top.jpg`

const styles = theme => ({
  background: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundColor: "#ffffff", // Average color of the background image.
    backgroundPosition: "center"
  },
  welcomeText: {
    marginTop: theme.spacing(12)
  },
  logoImg: {
    maxWidth: 120,
    marginTop: '10%'
  },
  button: {
    minWidth: 200
  },
  h5: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(5)
    }
  },
  more: {
    marginTop: theme.spacing(2)
  }
});

function ProductHero(props) {
  const { classes } = props;

  return (
    <ProductHeroLayout backgroundClassName={classes.background}>
      {/* Increase the network loading priority of the background image. */}
      <img style={{ display: "none" }} src={backgroundImage} alt="" />
      <img
        src={`${imgBase}/logo.svg`}
        alt="logo"
        className={classes.logoImg}
      />
      <Typography className={classes.welcomeText} color="inherit" align="center" variant="h2" marked="center">
        Welcome
      </Typography>
      <br />
      <Typography
        color="inherit"
        align="center"
        variant="h5"
        className={classes.h5}
      >
        Enjoy the free workouts with or without equipment.
      </Typography>
      <Button
        color="secondary"
        variant="contained"
        underline="none"
        size="large"
        className={classes.button}
        component="a"
        href="/signup"
      >
        Signup
      </Button>
      <Typography variant="body2" color="inherit" className={classes.more}>
        Become a person you've always admired!
      </Typography>
    </ProductHeroLayout>
  );
}

ProductHero.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductHero);
