import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getWorkoutById, setWorkout, logWorkout } from '../../actions/workout-data'
import { setAlert } from '../../actions/alert'
import BackgroundWrapper from '../layout/background'

import { Exercise, checkAllSetsComplete, checkIfSuperset } from './exercise'
import Spinner from '../layout/spinner'

import './workout-page.css'

const WorkoutPage = ({ match, workout, activeWorkout, loading = true, getWorkoutById, setWorkout, logWorkout, setAlert }) => {

    const [ workoutActive, setWorkoutActive ] = useState(false)
    const [ workoutData, setWorkoutData ] = useState(null)

    const matchId = parseInt(match.params.id)
    const dateObj = new Date()
    const dateClient = Date.now() - dateObj.getTimezoneOffset()*60000

    useEffect(() => {
        if (activeWorkout.woId === matchId) {
            setWorkoutData(activeWorkout)
            setWorkoutActive(true)
        } else {
            getWorkoutById(matchId)
            setWorkoutActive(false)
        }
    }, [getWorkoutById, matchId, activeWorkout])

    const currentWorkout = workoutActive ?  activeWorkout : workout
    const { exercises } = currentWorkout

    const toggleStart = (isActive) => {
        if (!isActive) {
            getWorkoutById(matchId) // Crutch to refresh state and close all exercises
            setWorkoutActive(true)
            setWorkout(currentWorkout) // ACTION
            setWorkoutData(currentWorkout) // USE STATE VAR
            setAlert('Make sure to warm up before exercises!', 'success', 2000)
        } else if (window.confirm('Do you want to finish your workout?')) {
                setWorkoutActive(false)
                logWorkout(activeWorkout.woId, activeWorkout.name, activeWorkout.muscles, dateClient)
                setWorkout([])
        }
    }

    // Triggered in Set
    const markSet = (exArrId, setArrId, isSetCompleted) => {
    
        let updatedWorkout = workoutData
        updatedWorkout.exercises[exArrId].sets[setArrId].complete = isSetCompleted

        if ((checkAllSetsComplete(updatedWorkout.exercises[exArrId].sets)) && (updatedWorkout.exercises.length > exArrId + 1)) {
            updatedWorkout.exercises[exArrId + 1].expanded = true
            // updatedWorkout = { ...workoutData, exercises: [...workoutData.exercises.slice(0, exArrId + 1), {...workoutData.exercises[exArrId + 1], expanded: true},...workoutData.exercises.slice(exArrId + 2)] }
        }
        setWorkoutData(updatedWorkout)
        setWorkout(updatedWorkout)

    }

    // Open/Close exercise
    const collapsibleMainController = (exArrId, isOpen, isDouble) => {

        if (workoutActive) {
            let updatedWorkout 
            if (isDouble) {
            updatedWorkout = { 
                ...workoutData, 
                exercises: [
                    ...workoutData.exercises.slice(0, exArrId), 
                    {
                        ...workoutData.exercises[exArrId], 
                        expanded: isOpen
                    },
                    {
                        ...workoutData.exercises[exArrId + 1], 
                        expanded: true
                    },
                    ...workoutData.exercises.slice(exArrId + 2)
                ]}
            } else {
                updatedWorkout = { 
                    ...workoutData, 
                    exercises: [
                        ...workoutData.exercises.slice(0, exArrId), 
                        {
                            ...workoutData.exercises[exArrId], 
                            expanded: isOpen
                        },
                        ...workoutData.exercises.slice(exArrId + 1)
                    ]}
            }
            setWorkoutData(updatedWorkout)
            setWorkout(updatedWorkout)
        }
    }

    return (loading) ? <Spinner /> : (
        <div>
            <BackgroundWrapper />
            <h3 className='text-center header'>{ currentWorkout.name }</h3>
            <div className='content-desktop'>
                {
                    exercises && exercises.map((ex, n) => {
                        let expanded, isSupersetTail
                        if (!workoutData || (workoutData.exercises[n].expanded === null) || ((typeof workoutData.exercises[n].expanded) === 'undefined')) {
                            expanded = false
                        } else {
                            expanded = workoutData.exercises[n].expanded
                        }
                        if (workoutData && (n > 0)) {
                            isSupersetTail = checkIfSuperset(workoutData.exercises[n-1].sets)
                        } else {
                            isSupersetTail = false
                        }
                        return <Exercise
                            setAlert={setAlert}
                            ex={ex}
                            arrNum={n}
                            key={n}
                            workoutActive={workoutActive}
                            markSet={markSet}
                            expanded={expanded}
                            collapsibleMainController={collapsibleMainController}
                            isSupersetTail={isSupersetTail}
                            />
                    })
                }
                <button
                    className={ workoutActive ? 'start-btn btn-aqua shadow' : 'start-btn btn-aqua btn-animated shadow' }
                    onClick={() => {
                        toggleStart(workoutActive)
                    }}>
                    { workoutActive ? 'Stop Workout' : 'Start Workout' }
                </button>
            </div>
        </div>
    )
}

WorkoutPage.propTypes = {
    getWorkoutById: PropTypes.func.isRequired,
    setWorkout: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    workout: state.workoutData.workout,
    loading: state.workoutData.loading,
    activeWorkout: state.workoutData.activeWorkout
})

export default connect(mapStateToProps, { getWorkoutById, setAlert, setWorkout, logWorkout })(WorkoutPage)
