import React from 'react'
import { Link } from 'react-router-dom'

import './label.css'

import configData from '../../../config.json'

const imgBase = `${configData.SERVER_URL}categories/`

const Categorylabel = ({ category }) => {
    const { catId, name, image, level } = category

    const levelView = level ? <div className="level-badge">{ level }</div> : null
    return (
        <div className="cat-card">
            <div className="card shadow">
                { levelView }
                <Link to={`/categories/${catId}`} >
                    <img src={`${imgBase}${image}`} alt="workout" />
                    <div className="title gradient-light-orange"></div>
                    <p className='col-orange-1 white-text-shadow'>{ name }</p>
                </Link>
            </div>
        </div>
    )
}

export default Categorylabel
