import {
    GET_CATEGORIES,
    GET_WORKOUTS,
    GET_WORKOUT_BY_ID,
    WORKOUT_REQUESTED,
    SET_WORKOUT
} from '../actions/types'

const initialState = {
    categories: [],
    workouts: [],
    workout: [],
    activeWorkout: [],
    loading: true
}

const workoutData = (state = initialState, action) => {
    const { type, payload } = action

    switch(type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: payload,
                loading: false
            }
        case WORKOUT_REQUESTED:
            return {
                ...state,
                workout: [],
                workouts: [],
                loading: true
            }
        case GET_WORKOUTS:
            return {
                ...state,
                workouts: payload,
                loading: false
            }
        case GET_WORKOUT_BY_ID:
            return {
                ...state,
                workout: payload,
                loading: false
            }
        case SET_WORKOUT:
            return {
                ...state,
                activeWorkout: payload
            }

        default:
            return state

    }
}

export default workoutData
