import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getWorkouts } from '../../actions/workout-data'
import { clearAlerts } from '../../actions/alert'
import { Workoutlabel } from '../layout/labels'
import Spinner from '../layout/spinner'
import BackgroundWrapper from '../layout/background'
import EmptyView from '../layout/empty-view'

import './category-page.css'

const CategoryPage = ({ match, workouts, getWorkouts, clearAlerts, loading }) => {
    useEffect(() => {
        getWorkouts(match.params.id)
        clearAlerts()
    }, [getWorkouts, clearAlerts, match.params.id])

    const workoutsView = workouts.map((workout) => {
        return (
            <Workoutlabel key={workout.woId} workout={workout} />
        )
    })

    return (loading) ? <Spinner /> : (
        <div className='list-wrapper'>
            <BackgroundWrapper />
            { (workouts.length > 0) ? workoutsView : <EmptyView message='Coming soon :)' /> }
        </div>
    )

}

CategoryPage.propTypes = {
    getWorkouts: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    workouts: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    workouts: state.workoutData.workouts,
    loading: state.workoutData.loading
})

export default connect(mapStateToProps, { getWorkouts, clearAlerts })(CategoryPage)
