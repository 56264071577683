import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getCategories } from '../../actions/workout-data'
import { clearAlerts } from '../../actions/alert'
import { Categorylabel } from '../layout/labels'
import Spinner from '../layout/spinner'
import BackgroundWrapper from '../layout/background'

import './home.css'

const Home = ({ getCategories, clearAlerts, categories, loading }) => {
    useEffect(() => {
        getCategories()
        clearAlerts()
    }, [getCategories, clearAlerts])

    const categoriesView = categories.map((category) => {
        return (
            <Categorylabel key={category.catId} category={category} />
        )
    })

    return (loading) ? <Spinner /> : (
        <div className='list-wrapper'>
            <BackgroundWrapper />
            {categoriesView}
        </div>
    )
}

Home.propTypes = {
    getCategories: PropTypes.func.isRequired,
    clearAlerts: PropTypes.func.isRequired,
    categories: PropTypes.array.isRequired
}

const mapStateToProps = state => ({
    categories: state.workoutData.categories,
    loading: state.workoutData.loading
})

export default connect(mapStateToProps, { getCategories, clearAlerts })(Home)
