import React from 'react'
import BackgroundWrapper from '../background'

import './spinner.css'

const Spinner = () => {
    return (
        <BackgroundWrapper>
            <div className="col text-center">
                <div className="loadingio-spinner-double-ring-ed3iaftsicn">
                    <div className="ldio-82n6jrgbepw">
                        <div></div>
                        <div></div>
                        <div>
                            <div></div>
                        </div>
                        <div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundWrapper>
    )
}

export default Spinner
