import PlayerColumn from "./playerColumn"
import BackgroundWrapper from "../layout/background"
import { useState } from 'react'

import './style.css'

const DebCounter = () => {

    const [ numOfRounds, setNumOfRounds ] = useState(1)

    return (
        <div>
            <BackgroundWrapper />
            <div className="container content-desktop">
                <div className="row">
                    <div className="col d-flex justify-content-center">
                        <PlayerColumn numOfRounds = { numOfRounds } />
                    </div>
                    <div className="col d-flex justify-content-center">
                        <PlayerColumn numOfRounds = { numOfRounds } />
                    </div>
                    <div className="col d-flex justify-content-center">
                        <PlayerColumn numOfRounds = { numOfRounds } />
                    </div>
                    <div className="col d-flex justify-content-center">
                        <PlayerColumn numOfRounds = { numOfRounds } />
                    </div>
                </div>
                <button className="add-row-btn btn-orange shadow" onClick={() => {
                    setNumOfRounds(numOfRounds + 1)
                }}>Add Round</button>
            </div>
        </div>
    )
}

export default DebCounter