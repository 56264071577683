import image from './from-author.jpg'
import BackgroundWrapper from '../layout/background'

const AboutUs = () => {
    return (
        <div>
            <BackgroundWrapper />
            <div className='content-desktop'>
                <img src={image} className='fullw-img' alt='from-author' />
                <h2 className='text-blue art-header'>Greetings!</h2>
                <p className='art-body'>
                    Enjoy free workouts and achieve better results with our community.
                </p>
                <p className='art-body'>
                    My North Star Metric in this app is only related to your progress. 
                    Nothing can please me more than healthier you - 
                    the number of pounds you lost, how many burpees you've made this week, your first five pull ups.
                </p>
                <p className='art-body pad-bot'>
                    Start with small simple steps and be ready to meet a better self!
                </p>
            </div>
        </div>
    )
}

export default AboutUs