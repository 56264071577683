import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Nav, NavItem} from 'reactstrap'
import { connect } from 'react-redux'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faRunning } from '@fortawesome/free-solid-svg-icons'
import {
	HomeIcon,
	MyWorkoutsIcon,
	EventsIcon,
	ProfileIcon,
	WeightIcon
} from '../../../assets/icons'

import './navigation.css'

const tabs = [{
	route: "/home",
	icon: <HomeIcon />,
  	label: "Home"
},{
    route: "/personalized",
    icon: <MyWorkoutsIcon />,
label: "Personal"
},{
    route: "/events",
    icon: <EventsIcon />,
label: "Events"
},{
    route: "/ex-list",
    icon: <WeightIcon />,
    label: "Exercises"
},{
    route: "/profile",
    icon: <ProfileIcon />,
    label: "Profile"
}]


const Topnav = ({ back, currentWorkout }) => {

	return (
		<nav className="navbar navbar-top navbar-expand-md navbar-light sticky-top" role="navigation">
			<div className="container-fluid content-desktop-large">
				{ back }
				<Nav className="ml-auto">
					<NavItem>
						{ currentWorkout }
					</NavItem>
				</Nav>
			</div>
		</nav>
	)
}

const Tabbar = () => {

	return (
		<nav className="navbar fixed-bottom navbar-light bottom-tab-nav shadow-lg" role="navigation">
			<Nav className="w-100">
				<div className=" d-flex flex-row justify-content-around w-100 content-tablet">
					{
					  tabs.map((tab, index) => (
							<NavItem key={`tab-${index}`}>
								<NavLink to={tab.route} className="nav-link bottom-nav-link" activeClassName="active">
									<div className="row d-flex flex-column justify-content-center align-items-center">
										{tab.icon}
										<div className="bottom-tab-label">{tab.label}</div>
									</div>
								</NavLink>
							</NavItem>
						))
					}
				</div>
			</Nav>
		</nav>
	)
}

const Navigation = ({ activeWorkout, match }) => {

	const [view, setView] = useState(null)
	const back = useRef(null)
	const currentWorkout = useRef(null)

	const history = useHistory()
	const location = useLocation()
	const path = location.pathname

	useEffect(() => {
		if (activeWorkout.woId && location.pathname !== `/workouts/${activeWorkout.woId}`) {
			currentWorkout.current = <NavLink to={`/workouts/${activeWorkout.woId}`} className="nav-link nav-top-active">
										<FontAwesomeIcon className="space" icon={faRunning} />
										Active Workout
									</NavLink>
		} else {
			currentWorkout.current = <p className="nav-text">ASV</p>
		}

		if (path === '/home' || path === '/personalized' || path === '/profile' || path === '/events' || path === '/') {
			back.current = null
		} else {
			back.current = <button onClick={() => history.goBack()}>
								<FontAwesomeIcon size="lg" icon={faChevronLeft} />
							</button>
		}

		if (path.includes('/login') || path.includes('/signup') || path.includes('/debertz') || path.includes('/passrecovery') || path.includes('/setpassword') || path === '/') {
			setView(null)
		} else if (path.includes('/workouts')) {
			setView(
				<Fragment>
					<Topnav
						back={back.current}
						currentWorkout={currentWorkout.current} />
				</Fragment>
			)
		} else {
			setView(
				<Fragment>
					<Topnav
						back={back.current}
						currentWorkout={currentWorkout.current} />
					<Tabbar />
				</Fragment>

			)
		}
  	}, [location, history, path, activeWorkout.woId])

	return (
		<div>
			{view}
		</div>
  	)
}

const mapStateToProps = state => ({
    activeWorkout: state.workoutData.activeWorkout
})

export default connect(mapStateToProps, {})(Navigation)
