import axios from 'axios'
import { setAlert } from './alert'

import configData from '../config.json'

// import {
//     GET_CATEGORIES
// } from './types'

export const requestRecoveryEmail = (email) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const emailString = JSON.stringify({ email })

    try {
        await axios.post(`${configData.API_URL}users/passrecovery`, emailString, config)

        dispatch(setAlert('Please check your inbox for a recovery email', 'success', 3000))

    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
        }
    }
}

export const checkRecoveryToken = (token) => async dispatch => {
    try {
        await axios.get(`${configData.API_URL}users/checkpasstoken/${token}`)

        dispatch(setAlert('Please set your new password', 'success', 2000))

        return true
    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
        }
        return false
    }
}

export const setNewPassword = (token, password) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ token, password })

    try {
        await axios.post(`${configData.API_URL}users/setpassword`, body, config)

        dispatch(setAlert('A new password has been set!', 'success', 3000))

        return true

    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
        }
    }
}

export const changePassword = (oldPassword, newPassword) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ oldPassword, newPassword })

    try {
        await axios.post(`${configData.API_URL}users/changepass`, body, config)
        dispatch(setAlert('A new password has been set!', 'success', 3000))
    } catch (err) {
        const errors = err.response.data.errors
        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
        }
    }
}
