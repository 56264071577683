import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { setAlert } from '../../actions/alert'
import { requestPersonalPlan } from '../../actions/workout-data'
import { loadUser } from '../../actions/auth'
import RequestPersonalWorkout from './requestPersonalWorkout'
import PersonalWorkoutList from './personalWorkoutList'
import Spinner from '../layout/spinner'

import './personalWorkoutsPage.css'

const PersonalWorkoutsPage = ({ workouts, loading, requestPersonalPlan, setAlert, loadUser }) => {
    
    useEffect(() => {
        loadUser()
    }, [loadUser])

    const [ personalView ] = useState((!workouts || (workouts.length === 0)) ?
        <RequestPersonalWorkout 
        requestPersonalPlan={requestPersonalPlan} 
        setAlert={setAlert} /> :
        <PersonalWorkoutList workouts={workouts} />)

    return (!personalView || loading) ? <Spinner /> : personalView

}

PersonalWorkoutsPage.propTypes = {
    requestPersonalPlan: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    workouts: state.auth.user && state.auth.user.workouts,
    loading: state.auth.loading
})


export default connect(mapStateToProps, { requestPersonalPlan, setAlert, loadUser })(PersonalWorkoutsPage)
