import BackgroundWrapper from '../layout/background'
import questsImage from './Quests.png'

import './character.css'


const SkillPage = () => {
    return (
        <div>
            <BackgroundWrapper />
            <div className='content-desktop'>
                <img className="img-center-animated img-profile" src={questsImage} alt="Profile Icon" />
                <div className="profile-menu shadow">
                    <h3 className="text-center mt-4">XenoQuests</h3>
                    <p className="text-center mt-8">Improve your performance by challenging others</p>
                    <ul className='character-stats-list'>
                        <p className='art-body'><span className='set-number'>New</span>Xenosportsman, it’s time to embark on a quest to enhance your dexterity, build strength, and elevate your overall ranking. </p>
                        <p className='art-body'><span className='set-number'>1</span>Push your limits, complete challenges, and watch your stats soar. Another way to boost your rank?</p>
                        <p className='art-body'><span className='set-number'>2</span>Compete in exclusive Clan Challenges! Team up, face rivals, and prove your dominance in the arena.</p>
                        <p className='art-body'>The path to greatness begins now—will you take it?</p>
                    </ul>
                    <p className='art-body mvp-btn-quest'><span className='notes-icon'>Accept Quest</span></p>
                </div>
                <p></p>
            </div>
        </div>
    )
}

export default SkillPage