import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { setAlert } from '../../../actions/alert'
import { register } from '../../../actions/auth'
import logo from '../logo.png'
import PropTypes from 'prop-types'

// import './signup.css'

const Signup = ({ setAlert, register, isAuthenticated }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password:'',
        password2:''
    })

    const {name, email, password, password2} = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async e => {
        e.preventDefault()
        if (password !== password2) {
            setAlert('Passwords do not match', 'danger')
        } else {
            register({ name, email, password })
        }
    }

    if (isAuthenticated) {
        return <Redirect to='/home' />
    }

    return (
        <Fragment>
            <div className="back">
                <Link to="/"><img src={logo} className="logo-img" alt="logo" /></Link>
                <div className="d-flex justify-content-center">
                    <section className="auth-form shadow">
                        <h1>Sign Up</h1>
                        <p className="lead">Create Your Account</p>
                        <form className="form" onSubmit={e => onSubmit(e)}>
                          <div className="form-group">
                            <input
                                className="form-control line-input"
                                type="text"
                                placeholder="Name"
                                name="name"
                                value={name}
                                onChange={e => onChange(e)}

                            />
                          </div>
                          <div className="form-group">
                            <input
                                className="form-control line-input"
                                type="email"
                                placeholder="Email Address"
                                name="email"
                                value={email}
                                onChange={e => onChange(e)}

                            />
                          </div>
                          <div className="form-group">
                            <input
                                className="form-control line-input"
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={password}
                                onChange={e => onChange(e)}
                            />
                          </div>
                          <div className="form-group">
                            <input
                                className="form-control line-input"
                                type="password"
                                placeholder="Confirm Password"
                                name="password2"
                                value={password2}
                                onChange={e => onChange(e)}
                            />
                          </div>
                          <input type="submit" className="btn-aqua btn-auth shadow" value="Register" />
                        </form>
                        <p>
                          Already have an account? <Link className="orange-link" to="/login">Sign In</Link>
                        </p>
                    </section>
                </div>
            </div>

        </Fragment>
    )
}

Signup.propTypes = {
    setAlert: PropTypes.func.isRequired,
    register: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { setAlert, register })(Signup)
