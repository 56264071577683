import React, { Fragment } from "react"
import withRoot from "./modules/withRoot"

// components
import NavbarView from "./modules/views/NavbarView"
import ProductHero from "./modules/views/ProductHero"
import ProductValues from "./modules/views/ProductValues"
import ProductCategories from "./modules/views/ProductCategories"
import ProductHowItWorks from "./modules/views/ProductHowItWorks"
// import ProductCTA from "./modules/views/ProductCTA"
// import ProductSmokingHero from "./modules/views/ProductSmokingHero"
import AppFooter from "./modules/views/AppFooter"

// redux
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

const Landing = ({ isAuthenticated, loading }) => {
  if (!isAuthenticated && !loading) {
    return (
      <Fragment>
        <NavbarView />
        <ProductHero />
        <ProductValues />
        <ProductCategories />
        <ProductHowItWorks />
        {/* <ProductCTA />
        <ProductSmokingHero /> */}
        <AppFooter />
      </Fragment>
    )      
  } else {
    return <Redirect to='/home' />
  }


}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading
})

export default connect(mapStateToProps)(withRoot(Landing))
