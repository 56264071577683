import React from 'react'
import { FitLady } from '../../../assets/icons'
import './empty-view.css'

export const EmptyView = ({ message = 'There is nothing here yet' }) => {
    return (
        <div className='empty-view-block'>
            <h4 className='col-grey-2'>{ message }</h4>
            <FitLady />
        </div>
    )
}
