import React from 'react'

import './background-wrapper.css'

const BackgroundWrapper = ( props ) => {
    return (
        <div className='back-general'>
            { props.children }
        </div>
    )
}

export default BackgroundWrapper
