import { useState } from 'react'


type PlayerColumnType = {
    numOfRounds: number;
};

const PlayerColumn = ({ numOfRounds } : PlayerColumnType ) => {
    
    const [ stats, setStats ] = useState(["0"])
    const [ roundScore, setRoundScore ] = useState("")
    const [ totalScore, setTotalScore ] = useState(0)
    const [ playerName, setPlayerName ] = useState('player')
    
    const handleChange = (value: string) => {
        setRoundScore(value)
    }

    const calcTotal = (arr : string[], roundScore : string) => {
        let total = 0
        arr.map((el) => {
            if (!isNaN(parseInt(el))) {
                total = total + parseInt(el)
            }
            return null
        })
        if (!isNaN(parseInt(roundScore))) {
            total = total + parseInt(roundScore)
        }
        setRoundScore("")
        setTotalScore(total)
    }

    if ((numOfRounds > 0) && (numOfRounds > stats.length)) {
        calcTotal(stats, roundScore)
        setRoundScore("")
        console.log(`${numOfRounds} vs ${stats.length}`)
        setStats([...stats, roundScore])
    }
    const statsView = (stats: string[]) => {
        if (stats.length === 1) {
            return <p>Please type in the score for the first round</p>
        } else {
            const statsToView = stats.slice(1)
            return statsToView.map((score, index) => {
                return (
                    <div key={index}>       
                        <p><span className="set-number">{index + 1}</span>{score}</p>
                    </div>
                    )    
                })
        }
    }

    return (
        <div>
            <input type="text" value={playerName} className="score-input" onChange={(e) => {setPlayerName(e.target.value)} } />
            {statsView(stats)}
            <p>
                <input type="text" value={roundScore} className="score-input"
                    onChange={(e) => {
                        e.preventDefault()
                        handleChange(e.target.value)
                    }}
                />
            </p>
            <p>{playerName} score: {totalScore}</p>

        </div>
    )
}

export default PlayerColumn