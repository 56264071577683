import configData from '../../../config.json'

import './style.css'

const musclesBadges = (musclesArr) => {
    return (
        musclesArr.map((muscle, n) => {
            return (
                <div className="muscle-item" key={n}>
                    <img
                        className="muscle-img"
                        src={`${configData.SERVER_URL}muscles/${muscle}.png`}
                        alt="muscles"
                    />
                    <span className="muscle-name">{` ${muscle}`}</span>
                </div>
            )
        })
    )
}

export default musclesBadges