import React, { Fragment, useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { requestRecoveryEmail } from '../../../actions/user'
import logo from '../logo.png'

import './pass-recovery.css'

const PassRecovery = ({ requestRecoveryEmail, isAuthenticated }) => {
    const [email, setEmail] = useState('')

    const onChange = e => setEmail(e.target.value)

    const onSubmit = async e => {
        requestRecoveryEmail(email)
        setEmail('')
        e.preventDefault()
    }

    // Redirect if logged in
    if (isAuthenticated) {
        return <Redirect to="/home" />
    }

    return (
        <Fragment>
            <div className="back">
                <Link to="/"><img src={logo} className="logo-img" alt="logo" /></Link>
                <div className="d-flex justify-content-center">
                    <section className="auth-form shadow">
                        <h1>Password <br/> Recovery</h1>
                        <p className="lead">Please type in your email</p>
                        <form className="form" onSubmit={e => onSubmit(e)}>
                            <div className="form-group">
                                <input
                                    className="form-control line-input"
                                    type="email"
                                    placeholder="Email Address"
                                    name="email"
                                    required
                                    value={email}
                                    onChange={e => onChange(e)}
                                />
                            </div>
                            <input type="submit" className="btn-aqua btn-auth shadow" value="Send" />
                        </form>
                        <Link className="orange-link d-flex justify-content-center" to="/login">Back to Login</Link>
                    </section>
                </div>
            </div>
        </Fragment>
    )
}

PassRecovery.propTypes = {
    requestRecoveryEmail: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { requestRecoveryEmail })(PassRecovery)
