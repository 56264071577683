import React, { useEffect, useState } from 'react'
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import axios from 'axios'
import { transDateMDYHMA } from '../helpers'
import { connect } from 'react-redux'
import { loadUser } from '../../actions/auth'
import { setAlert } from '../../actions/alert'

import moment from 'moment'

import PropTypes from 'prop-types'

import Spinner from '../layout/spinner'
import BackgroundWrapper from '../layout/background'

import './events.css'

import configData from '../../config.json'

const imgBase = `${configData.SERVER_URL}events`
const reqBase = `${configData.API_URL}events`

const MapContainer = ({ name, coords }) => {
  
    const mapStyles = {        
        height: "400px",
        width: "100%"};
    
    const defaultCenter = {
        lat: 40.7711614, 
        lng: -73.9538248
    }

    const location = { 
        lat: coords[0],
        lng: coords[1]
    }
    
    return (
        <LoadScript
            googleMapsApiKey={configData.GAPI_KEY}>
                <GoogleMap
                    mapContainerStyle={mapStyles}
                    zoom={13}
                    center={defaultCenter}
                >
                    <Marker key={name} position={location}/>
                </GoogleMap>
        </LoadScript>
    )
}

const checkAttendance = (list, id) => {
    if (list) {
        return (list.filter(el => (el.userId === id)).length > 0) ? true : false
    } else {
        return false
    }
}

const EventPage = ({ match, loading = true, loadUser, setAlert, userId }) => {

    const [ disabled, setDisabled ] = useState(false)
    const [ event, setEvent ] = useState(null)
    const [load, setLoad] = useState(loading)

    const attendance = event && checkAttendance(event.attendees, userId)

    const attendEvent = async (reqBase, evId) => {
        await axios.put(`${reqBase}/attend/${evId}`)
        setAlert(`You're in!`, 'success', 2000) 
    }
    const unattendEvent = async (reqBase, evId) => {
        await axios.patch(`${reqBase}/unattend/${evId}`)
        setAlert(`We hope to see you next time!`, 'success', 2000)
    }

    useEffect(() => {
        loadUser()
        axios.get(`${reqBase}/${match.params.id}`).then((res) => {
            setEvent(res.data)
            setLoad(false)
            setDisabled(false)
        })
    }, [setEvent, loadUser, match.params.id, load, disabled])

    return load ? <Spinner /> : (
        <div>
            <BackgroundWrapper />
            <div className='content-desktop pad-bot'>
                <div className='event-header'>
                    <p className='event-date'>{transDateMDYHMA(event.date)}</p> 
                    <div className='event-image-overlay'></div>
                    <img className='fullw-img' src={`${imgBase}/${event.image}`} alt='event' />
                    <p className='event-name'>{event.name}</p>
                    {
                        (moment(event.date) > moment()) ?
                        <button 
                            className='btn-attend'
                            disabled={ disabled }
                            onClick={() => {
                                setDisabled(true)
                                setLoad(true)
                                attendance ? unattendEvent(reqBase, match.params.id) : attendEvent(reqBase, match.params.id)
                            }} >
                                { attendance ? 'Unattend' : 'Attend' }
                        </button> : null
                    }


                </div>
                <div className='pad-lr-10'>
                    <p><span className="notes-icon">!</span>Please read:</p>
                    <p className='comments-text'>{event.comments}</p> 
                    <p className=''>{event.description}</p> 
                    <p>Attendees: {event.attendees.length} / {event.attendeesLimit}</p>
                </div>
                <MapContainer name={event.name} coords={event.location.coordinates} />
            </div>
        </div>
    )
}

EventPage.propTypes = {
    loadUser: PropTypes.func.isRequired,
    setAlert: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    userId: state.auth.user && state.auth.user._id
})

export default connect(mapStateToProps, { setAlert, loadUser })(EventPage)
