import React, { useState, useEffect } from 'react'
import useSound from 'use-sound'

import { Set } from './set'
import Timer from '../layout/timer'
import Collapsible from 'react-collapsible'

import configData from '../../config.json'

// Check if all sets are complete
export const checkAllSetsComplete = (sets) => {
    return sets.every((set) => set.complete)
}

// Check if all rest timers are 0 to determine Superset
export const checkIfSuperset = (sets) => {
    return sets.every((set) => set.rest === 0)
}

const imgBase = `${configData.SERVER_URL}exercises/`
const alertSound = 'https://whatisrock.com/content/sounds/alert.mp3'

export const Exercise = ({ ex, arrNum, setAlert, workoutActive, markSet, expanded, collapsibleMainController, isSupersetTail }) => {

    useEffect(() => {
        setCollapsibleIsOpen(expanded)
        
    }, [expanded])

    const { exercise, sets } = ex
    const muscleIconPath = `${configData.SERVER_URL}muscles/${exercise.primaryMuscles[0]}.png`
    const comments = ex.comments ? 
        <div>
            <p><span className="notes-icon">!</span>Notes:</p>
            <p className="comments-text">{ ex.comments }</p>
        </div> : null
    const isSuperset = checkIfSuperset(sets)
    const [ play ] = useSound(alertSound)
    const [ timer, setTimer ] = useState('')
    const [ imgClass, setimgClass ] = useState('')
    const [ stopBtn, setStopBtn ] = useState('')
    const [ completeBadge, setCompleteBadge ] = useState(
        (checkAllSetsComplete(sets) && !isSuperset) ? <span className="complete-badge">Complete</span> : null)
    const [ collapsibleIsOpen, setCollapsibleIsOpen ] = useState(false)

    const ifLast = (arr, n) => {
        if (arr.length <= (n + 1)) {
            return '.'
        } else {
            return ', '
        }
    }
    
    // PARTIALS
    // Superset Tip
    const supersetNote = (isSuperset || isSupersetTail) ?
        <span className="superset-note">
            <span className='superset-icon' 
                onClick={() => {
                    setAlert(`No rest until you're done with the next exercise's set`, 'success', 4000)}
                }>What is superset?
            </span>
        </span> : null

    // Superset Badge
    const superSetView =
        isSuperset ? 
            <div className='superset-indicator'>Superset!
            </div> 
        : null

    // Stop Timer Button
    const stopButtonView =
        <button className="stop-btn shadow" onClick={() => {
            setTimer(null)
            setimgClass('')
            setStopBtn(null)
        }}><div className="stop-icon"></div></button>

    // Collapsible Title
    const colTitle = <div>
        <img
            className="col-img"
            src={muscleIconPath}
            alt="muscles"
        />
        { completeBadge }
        { superSetView }
        <span className="col-title" id={ arrNum }>{ arrNum + 1 }. { exercise.name }</span>
    </div>
    // END PARTIALS

    // Change view when timer is done
    const timerComplete = () => {
        setimgClass('')
        setTimer('')
        setAlert('Time to work!', 'success', 2000)
        setStopBtn(null)
        play()
        if (isSupersetTail) {
            collapsibleMainController((arrNum - 1), true)
        }
    }

    // SET COMPLETE - Triggered in Set component when the set is marked as done
    const passData = (timeToRest = 0, checked, exArrId, setArrId) => {
        markSet(exArrId, setArrId, checked) // DECLARED IN WorkoutPage COMPONENT
        if (isSuperset && checked) {
            collapsibleMainController(arrNum, false, true)
        }
        if (checked && !checkAllSetsComplete(sets) && !isSuperset && (timeToRest > 0)) {
            setimgClass('image-op')
            setTimer(<Timer time={timeToRest} completeFunc={() => timerComplete()} />)
            setStopBtn(stopButtonView)
        }
        if (checkAllSetsComplete(sets)) {
            if (!isSuperset) {
                setCompleteBadge(<span className="complete-badge">Complete</span>)
            }
            setTimer(null)
            setimgClass('')
            setStopBtn(null)

            collapsibleMainController(arrNum, false)
        } else {
            setCompleteBadge(null)
        }
    }

    return (
        <Collapsible
            trigger={ colTitle }
            transitionTime={ 300 }
            onTriggerOpening={() => {
                    collapsibleMainController(arrNum, true)
                }
            }
            onTriggerClosing={() => {
                    collapsibleMainController(arrNum, false)
                }
            }
            open={collapsibleIsOpen}
            >
            <div className="exercise">
                <div className="timer-wrapper">
                    { timer }
                    <div className="gradient"></div>
                    <div className="gradient-side"></div>
                    <img
                        className={imgClass}
                        src={`${imgBase}${exercise.image}`}
                        alt="exercise"
                        />
                </div>
                { stopBtn }
                <div className="sets shadow">
                    {
                        sets.map((set, n) => {
                            return <Set
                                set={set}
                                num={n+1}
                                exId={ exercise.exId }
                                exArrId={arrNum}
                                passData={passData}
                                key={n}
                                workoutActive={workoutActive}
                                setAlert={setAlert} />
                        })
                    }
                </div>
                <div className="ex-description">
                    { supersetNote }
                    { comments }
                    <p className="ex-muscles">Primary Muscles: {
                            exercise.primaryMuscles.map((muscle, n) => {
                                return <span key={n}>{ muscle }{ ifLast(exercise.primaryMuscles, n) }</span>
                            })
                        }
                    </p>
                </div>
            </div>
        </Collapsible>
    )
}