import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'
import { setAlert } from '../../actions/alert'
import { changePassword } from '../../actions/user'

const ChangePass = ({ setAlert, changePassword }) => {
    const initialState = {
        oldPass:'',
        password:'',
        password2:''
    }

    const [formData, setFormData] = useState(initialState)

    const { oldPass, password, password2 } = formData

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value })

    const onSubmit = async e => {
        if (password !== password2) {
            setAlert('New passwords do not match', 'danger')
        } else {
            changePassword(oldPass, password)
            setFormData(initialState)
        }
        e.preventDefault()
    }

    return (
        <Fragment>
                <div className="d-flex justify-content-center">
                    <section className="auth-form shadow">
                        <p className="lead">Change Password</p>
                        <form className="form" onSubmit={e => onSubmit(e)}>
                            <div className="form-group">
                                <input
                                    className="form-control line-input"
                                    type="password"
                                    placeholder="Old Password"
                                    name="oldPass"
                                    value={oldPass}
                                    minLength="6"
                                    onChange={e => onChange(e)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control line-input"
                                    type="password"
                                    placeholder="New Password"
                                    name="password"
                                    value={password}
                                    minLength="6"
                                    onChange={e => onChange(e)}
                                    required
                                />
                            </div>
                            <div className="form-group">
                                <input
                                    className="form-control line-input"
                                    type="password"
                                    placeholder="Confirm New Password"
                                    name="password2"
                                    value={password2}
                                    minLength="6"
                                    onChange={e => onChange(e)}
                                    required
                                />
                            </div>
                            <input type="submit" className="btn-aqua btn-auth shadow" value="Change Password" />
                        </form>
                    </section>
                </div>
        </Fragment>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps, { setAlert, changePassword })(ChangePass)
