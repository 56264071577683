import axios from 'axios'
import { setAlert } from './alert'

import {
    GET_CATEGORIES,
    GET_WORKOUTS,
    GET_WORKOUT_BY_ID,
    WORKOUT_REQUESTED,
    SET_WORKOUT
} from './types'

import configData from '../config.json'

// Get all categories
export const getCategories = () => async dispatch => {

    dispatch({
        type: WORKOUT_REQUESTED
    })

    try {
        const res = await axios.get(`${configData.API_URL}categories`)
        dispatch({
            type: GET_CATEGORIES,
            payload: res.data
        })
    } catch (err) {
        console.log(err)
    }
}

// Get Workouts by Category
export const getWorkouts = (catId) => async dispatch => {

    dispatch({
        type: WORKOUT_REQUESTED
    })

    try {
        const res = await axios.get(`${configData.API_URL}workouts/cat/${catId}`)
        dispatch({
            type: GET_WORKOUTS,
            payload: res.data
        })
    } catch (err) {
        console.log(err)
    }
}

// Get Workout by id
export const getWorkoutById = (woId) => async dispatch => {

    dispatch({
        type: WORKOUT_REQUESTED
    })

    try {
        const res = await axios.get(`${configData.API_URL}workouts/${woId}`)
        dispatch({
            type: GET_WORKOUT_BY_ID,
            payload: res.data
        })
    } catch (err) {
        console.log(err)
    }
}

// Start Workout
export const setWorkout = (workout) => dispatch => {

    dispatch({
        type: SET_WORKOUT,
        payload: workout
    })

}

// Request a personal plan and use API method to send Email to admin
export const requestPersonalPlan = (message) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ message })

    try {
        await axios.post(`${configData.API_URL}users/requestplan`, body, config)

        dispatch(setAlert('Your message has been sent! We will get back via Email.', 'success', 7000))

    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
        }
    }
}

export const logWorkout = (woId, name, muscles, date) => async dispatch => {

    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ woId, name, muscles, date })

    try {
        await axios.post(`${configData.API_URL}workouts/log`, body, config)

        dispatch(setAlert('Good job! Workout saved to profile history!', 'success', 3500))

    } catch (err) {
        const errors = err.response.data.errors

        if (errors) {
            errors.forEach(error => dispatch(setAlert(error.msg, 'danger')))
        }
    }
}
